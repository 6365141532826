import { isEmpty, isNil } from "lodash";
import { Resolver, useForm } from "react-hook-form";

export type CrownChargesValidatorFormValues = {
  ordersUrl: string;
  invoicesUrl?: string;
  companyUuid: string;
};

const resolver: Resolver<CrownChargesValidatorFormValues> = async (
  formValues,
) => {
  const errors: {
    [property in keyof CrownChargesValidatorFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  const { ordersUrl } = formValues;

  if (isNil(ordersUrl) || isEmpty(ordersUrl)) {
    errors.ordersUrl = {
      type: "required",
      message: "Orders URL is required",
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useImportOrdersForm = () => {
  return useForm({ resolver });
};

export default useImportOrdersForm;
