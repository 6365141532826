import { isNil } from "lodash";
import { useState } from "react";
import { filterNotNil } from "shared/array";
import {
  ScannedOrderFromAdminCreateInput,
  useCreateScannedOrdersFromAdminMutation,
} from "../../../generated/graphql";
import useSchemaStore from "./schema-store";
import { Schema, SchemaDocument } from "./types";

const useScannedOrders = () => {
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>();
  const [
    createScannedOrdersFromAdmin,
    { loading: loadingCreateScannedOrders },
  ] = useCreateScannedOrdersFromAdminMutation();
  const addScanSchemaDocumentJob = useSchemaStore(
    (state) => state.addScanSchemaDocumentJob,
  );

  const createScannedOrders = async ({
    schemaDocuments,
  }: {
    schemaDocuments: {
      schemaDocument: SchemaDocument;
      schema: Schema;
    }[];
  }) => {
    const scannedOrderCreateInputs: ScannedOrderFromAdminCreateInput[] =
      filterNotNil(
        schemaDocuments.map(({ schemaDocument: doc }) => {
          if (isNil(doc.company)) return null;
          return {
            bucket: doc.bucket,
            key: doc.key,
            filename: doc.key.split("/").slice(-1)[0] ?? "",
            filetype: "application/pdf",
            companyUuid: doc.company.uuid,
          };
        }),
      );
    const res = await createScannedOrdersFromAdmin({
      variables: {
        createScannedOrdersFromAdminInput: {
          scannedOrderCreateInputs,
        },
      },
    });
    if (!isNil(res.errors)) {
      setErrorMessage(
        `Failed to create scanned orders ${res.errors[0]?.message}`,
      );
      return;
    }
    res.data?.createScannedOrdersFromAdmin.forEach(
      (createScannedOrderResult, i) => {
        if (!isNil(createScannedOrderResult.scannedOrderEntity)) {
          addScanSchemaDocumentJob({
            schema: schemaDocuments[i]?.schema,
            schemaDocument: schemaDocuments[i]?.schemaDocument,
            scannedOrderUuid: createScannedOrderResult.scannedOrderEntity.uuid,
          });
        } else {
          setErrorMessage(
            !isNil(errorMessage)
              ? `${errorMessage}\n${createScannedOrderResult.error ?? ""}`
              : createScannedOrderResult.error,
          );
        }
      },
    );
  };

  const createScannedOrderFromSchema = async ({
    schemaDocument,
    schema,
  }: {
    schemaDocument: SchemaDocument | null | undefined;
    schema: Schema | null | undefined;
  }) => {
    if (isNil(schemaDocument) || isNil(schema)) return;
    await createScannedOrders({
      schemaDocuments: [{ schemaDocument, schema }],
    });
  };

  return {
    createScannedOrders,
    createScannedOrderFromSchema,
    loadingCreateScannedOrders,
    errorMessage,
    setErrorMessage,
  };
};

export default useScannedOrders;
