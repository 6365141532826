import DriversImporter from "../../components/drivers-importer/drivers-importer";
import { RequireAuth } from "../../hooks/use-auth";

const DriversImporterPage = () => {
  return (
    <RequireAuth>
      <DriversImporter />
    </RequireAuth>
  );
};

export default DriversImporterPage;
