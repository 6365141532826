import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { useOrderVolumeBySchemaReportQuery } from "../../../generated/graphql";
import DateRangePicker, { DateOption } from "../../date-range-picker";

const OrderVolumeBySchemaStatistics = () => {
  const [dateOption, setDateOption] = useState<DateOption>({
    startDate: dayjs(new Date()).subtract(30, "days").toDate(),
    endDate: null,
  });
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { data: orderVolumeBySchemaData, loading } =
    useOrderVolumeBySchemaReportQuery({
      variables: {
        orderVolumeBySchemaInput: {
          startDate: dateOption.startDate,
          endDate: dateOption.endDate,
        },
      },
    });

  const header = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: 1,
        borderBottom: 0,
        borderColor: "#E9ECEF",
        backgroundColor: "#f8f9fa",
        p: 0.5,
      }}
    >
      <Stack>
        <Typography sx={{ fontWeight: "bold" }}>Order volume</Typography>
        <Typography variant="caption">(by schema)</Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <DateRangePicker
          dateOption={dateOption}
          onChange={(newDateOption) => setDateOption(newDateOption)}
        />
        <Divider orientation="vertical" flexItem />
        <IconButton
          size="small"
          onClick={() => {
            setIsExpanded((prev) => !prev);
          }}
        >
          {isExpanded ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
        </IconButton>
      </Stack>
    </Stack>
  );

  return (
    <div
      style={
        isExpanded
          ? {
              position: "absolute",
              zIndex: 10000,
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "white",
            }
          : {}
      }
    >
      {header}
      <DataTable
        key={`open-${isExpanded}`}
        value={orderVolumeBySchemaData?.orderVolumeBySchemaReport}
        sortMode="multiple"
        removableSort
        showGridlines
        rows={10}
        loading={loading}
        dataKey="id"
        emptyMessage="No data"
        size="small"
        scrollable
        scrollHeight={isExpanded ? "100vh" : "325px"}
        stripedRows
        virtualScrollerOptions={{ itemSize: 25 }}
      >
        <Column
          field="schemaName"
          header="Schema"
          sortable
          filter
          filterPlaceholder="Search by schema"
          style={{ width: "80%" }}
        />
        <Column header="Total" field="total" sortable />
      </DataTable>
    </div>
  );
};

export default OrderVolumeBySchemaStatistics;
