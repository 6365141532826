import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { isNil } from "lodash";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { exhaustive } from "shared/switch";
import {
  SchemaRunTestStatus,
  useSchemaRunTestListLazyQuery,
} from "../generated/graphql";
import { filenameFromKey } from "../utils/file";

const getStatusColor = (
  status: SchemaRunTestStatus | null | undefined,
): string => {
  switch (status) {
    case null:
    case undefined:
      return "black";
    case SchemaRunTestStatus.Passed:
      return "green";
    case SchemaRunTestStatus.Failed:
      return "red";
    case SchemaRunTestStatus.Error:
      return "red";
    case SchemaRunTestStatus.InProgress:
      return "yellow";
    default:
      return exhaustive(status);
  }
};

export const SchemaTestListPage = () => {
  const { uuid } = useParams();
  const [schemaRunTestListQuery, { data, loading }] =
    useSchemaRunTestListLazyQuery({
      variables: {
        schemaRunTestListInput: {
          schemaRunTestListUuid: uuid ?? "",
        },
      },
      pollInterval: 5000,
    });

  useEffect(() => {
    schemaRunTestListQuery();
  }, []);

  if (loading !== false) {
    return <div>Loading...</div>;
  }
  if (isNil(data)) {
    return <div>No data</div>;
  }
  return (
    <Box
      sx={{
        color: "black",
        display: "flex",
        padding: "32px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: getStatusColor(data.schemaRunTestList.schemaRunTestStatus),
        }}
      >
        Overall status: {data.schemaRunTestList.schemaRunTestStatus}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Test</TableCell>
              <TableCell>Status</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.schemaRunTestList.schemaRuns.map((schemaRun) => {
              return (
                <TableRow key={schemaRun.uuid}>
                  <TableCell>
                    {schemaRun.schema.name} {filenameFromKey(schemaRun.key)}
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: getStatusColor(schemaRun.schemaRunTestStatus),
                      }}
                    >
                      {schemaRun.schemaRunTestStatus}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      {!isNil(schemaRun.schemaRunTestSnapshot?.uuid) && (
                        <Button
                          onClick={() => {
                            window.open(
                              `/schema-runs/${schemaRun.schemaRunTestSnapshot?.uuid}`,
                              "_blank",
                            );
                          }}
                          sx={{ mr: "10px" }}
                          variant="contained"
                        >
                          View expected results
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          window.open(
                            `/schema-runs/${schemaRun.uuid}`,
                            "_blank",
                          );
                        }}
                        variant="contained"
                      >
                        View test results
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
