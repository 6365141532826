import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, Typography } from "@mui/material";
import { isNil } from "lodash";
import { useDropzone } from "react-dropzone";
import { getObjectUrlFromPresignedUrl } from "shared/string";
import { useGeneratePreSignedPutUrlMutation } from "../../generated/graphql";

export const DragAndDropUpload = ({
  fileUrl,
  setPresignedUrl,
  isSmall = false,
}: {
  fileUrl: string | undefined;
  setPresignedUrl: (url: string) => void;
  isSmall?: boolean;
}) => {
  const [generatePreSignedPutUrl] = useGeneratePreSignedPutUrlMutation();
  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (isNil(file)) {
      return;
    }

    const data = await generatePreSignedPutUrl({
      variables: {
        filename: file.name,
        filetype: file.type,
      },
    });
    const preSignedPutUrl = data.data?.generatePreSignedPutUrl;
    if (!isNil(preSignedPutUrl)) {
      await fetch(preSignedPutUrl, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type },
      });
      setPresignedUrl(preSignedPutUrl);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const fileName = getObjectUrlFromPresignedUrl(fileUrl)?.split("/")?.pop();

  return (
    <Box
      {...getRootProps()}
      border={2}
      borderRadius={4}
      borderColor={isDragActive ? "primary.main" : "text.secondary"}
      p={isSmall ? 1 : 4}
      textAlign="center"
      bgcolor={isDragActive ? "primary.light" : "background.paper"}
    >
      <input {...getInputProps()} />
      <Typography variant={isSmall ? "subtitle1" : "h6"} gutterBottom>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isNil(fileName)
          ? isDragActive
            ? "Drop the file here ..."
            : "Drag & drop or click to upload a file"
          : fileName}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
      >
        Upload
      </Button>
    </Box>
  );
};
