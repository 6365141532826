import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { isEmpty } from "lodash";
import ReactJson from "react-json-view";

interface ViewEmailResponseDialogProps {
  open: boolean;

  onClose: () => void;

  payload: string;
}
const ViewEmailResponseDialog = ({
  open,
  onClose,
  payload,
}: ViewEmailResponseDialogProps) => {
  if (isEmpty(payload)) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Email Response</DialogTitle>
      <DialogContent>
        <ReactJson src={JSON.parse(payload)} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewEmailResponseDialog;
