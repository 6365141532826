import { Grid } from "@mui/material";
import React from "react";
import OrderVolumeByCompanyStatistics from "./order-volume-by-company-statistics";
import OrderVolumeBySchemaStatistics from "./order-volume-by-schema-statistics";

const OverallStatistics = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <OrderVolumeByCompanyStatistics />
      </Grid>
      <Grid item xs={6}>
        <OrderVolumeBySchemaStatistics />
      </Grid>
    </Grid>
  );
};

export default OverallStatistics;
