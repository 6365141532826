import { Card, CardContent } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useOnScreen } from "../../common/use-on-screen";
import CompanyStatistics from "./company-statistics";
import { StatisticsView } from "./utils";

const CompanyStatisticsCard = ({
  tab,
  companyName,
  companyUuid,
}: {
  tab: StatisticsView;
  companyName: string;
  companyUuid: string;
}) => {
  const cardRef = useRef(null);
  const isVisible = useOnScreen(cardRef);
  const [shouldRender, setShouldRender] = useState<boolean>(false);
  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
    }
  }, [isVisible]);

  return (
    <Card variant="outlined" sx={{ minHeight: 855 }} ref={cardRef}>
      <CardContent sx={{ pt: 0, px: 1 }}>
        {shouldRender && (
          <CompanyStatistics
            companyName={companyName}
            companyUuid={companyUuid}
            tab={tab}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default CompanyStatisticsCard;
