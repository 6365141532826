import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  RateType,
  TariffGroupType,
  TariffType,
  TariffZoneType,
} from "../../generated/graphql";

yup.addMethod(yup.object, "dayjs", function method(message) {
  return this.test("dayjs", message, (value) => {
    if (isNil(value)) {
      return true;
    }
    return dayjs.isDayjs(value);
  });
});

export const importTariffFormSchema = yup.object({
  // Contact service mapping only exists on tariffs when tariff chains are disabled
  contactUuids: yup
    .array()
    .of(yup.string().required())
    .when(["tariffChainsEnabled"], {
      is: (tariffChainsEnabled: boolean) => tariffChainsEnabled,
      then: (schema) =>
        schema.max(0, "Must be empty when tariff chains are enabled"),
      otherwise: (schema) => schema.required(),
    }),
  serviceUuids: yup.array().of(yup.string().required()),
  // Vehicles types only exist on tariffs when tariff chains are enabled
  vehicleTypeUuids: yup
    .array()
    .of(yup.string().required())
    .when(["tariffChainsEnabled"], {
      is: (tariffChainsEnabled: boolean) => !tariffChainsEnabled,
      then: (schema) =>
        schema.max(0, "Must be empty when tariff chains are disabled"),
      otherwise: (schema) => schema,
    }),
  autoSelectAllServices: yup.boolean().required(),
  // Vehicles types only exist on tariffs when tariff chains are enabled
  autoSelectAllVehicleTypes: yup
    .boolean()
    .default(false)
    .when(["tariffChainsEnabled"], {
      is: (tariffChainsEnabled: boolean) => !tariffChainsEnabled,
      then: (schema) =>
        schema
          .is([false], "Must be false when tariff chains are disabled")
          .default(false),
      otherwise: (schema) => schema,
    }),
  name: yup.string().required(),
  tariffZoneType: yup
    .mixed<TariffZoneType>()
    .oneOf(Object.values(TariffZoneType))
    .required(),
  tariffZoneGroupId: yup.string().nullable().optional(),
  tariffType: yup
    .mixed<TariffType>()
    .oneOf(Object.values(TariffType))
    .required(),
  rateType: yup.mixed<RateType>().oneOf(Object.values(RateType)).required(),
  tariffGroupType: yup
    .mixed<TariffGroupType>()
    .oneOf(Object.values(TariffGroupType))
    .required(),
  useActualWeight: yup.boolean().required(),
  // @ts-expect-error dayjs extension not recognized
  startDate: yup.object().nullable().dayjs(),
  // @ts-expect-error dayjs extension not recognized
  endDate: yup.object().nullable().dayjs(),
  fuelProfileUuid: yup.string().nullable().optional(),
  tariffUrl: yup.string().required(),
  tariffChainsEnabled: yup.boolean().required().default(false),
});

export type ImportTariffFormValuesV2 = yup.InferType<
  typeof importTariffFormSchema
>;

export const useImportTariffForm = () => {
  const form = useForm({
    resolver: yupResolver(importTariffFormSchema),
    mode: "all",
  });

  return { form };
};
