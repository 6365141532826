/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  SchemaCompanyMappingsQuery,
  SchemaDocumentsBySchemaCompanyMappingQuery,
} from "../../../generated/graphql";

export type Schema = {
  uuid: string;
  name: string;
};

export type SchemaCompanyMapping =
  SchemaCompanyMappingsQuery["schemaCompanyMappings"][0];

export type SchemaDocument =
  SchemaDocumentsBySchemaCompanyMappingQuery["schemaDocumentsBySchemaCompanyMapping"][0];

export type SelectOption = {
  value: string;
  label: string;
};

export enum MatchStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export type MatchSchemaDocumentJob = {
  id: string;
  schema: Schema;
  schemaDocument: SchemaDocument;
  matchStatus: MatchStatus;
  startedAt: Date;
  finishedAt: Date | null;
  resultJson?: any;
  error?: string | null;
};

export type ScanSchemaDocumentJob = {
  id: string;
  schema?: Schema | null;
  schemaDocument?: SchemaDocument | null;
  resultScannedOrderUuid: string;
};

export type StructuredExtractionOverride = {
  label: string;
  path: string[];
  default: string;
};
export const generalOverrideBasePath = ["properties"];
export const generalExtractionSchemaOverrides: StructuredExtractionOverride[] =
  [
    {
      label: "HAWB",
      path: ["house_air_waybill"],
      default:
        "The house air waybill number, if present and able to be identified",
    },
    {
      label: "MAWB",
      path: ["master_air_waybill"],
      default:
        "The master air waybill number, if present and able to be identified",
    },
    {
      label: "Special Instructions",
      path: ["instructions"],
      default:
        "All natural language instructions which are present in the document and not already extracted into a more specific field",
    },
    {
      label: "Packages Description",
      path: ["packages"],
      default: "The packages in the shipment",
    },
  ];

export const shipperOverrideBasePath = [
  "properties",
  "shipper_details",
  "properties",
];
export const consigneeOverrideBasePath = [
  "properties",
  "consignee_details",
  "properties",
];
export const addressSchemaOverrides: StructuredExtractionOverride[] = [
  {
    label: "Details",
    path: [],
    default: "",
  },
  {
    label: "Address Name",
    path: ["name"],
    default: "The name of the shipper",
  },
  {
    label: "Line1",
    path: ["address", "properties", "line1"],
    default: "The first line of the address, e.g. street address or PO box",
  },
  {
    label: "Line2",
    path: ["address", "properties", "line2"],
    default:
      "The second line of the address, e.g. apartment, suite, unit, building, floor, etc.",
  },
  {
    label: "City",
    path: ["address", "properties", "city"],
    default: "The city",
  },
  {
    label: "State",
    path: ["address", "properties", "state"],
    default: "The state",
  },
  {
    label: "Zip",
    path: ["address", "properties", "zip"],
    default: "The zip code",
  },
  {
    label: "Contact First Name",
    path: ["contact", "properties", "first_name"],
    default: "The first name of the contact",
  },
  {
    label: "Contact Last Name",
    path: ["contact", "properties", "last_name"],
    default: "The last name of the contact",
  },
  {
    label: "Contact Phone",
    path: ["contact", "properties", "phone"],
    default: "The phone number of the contact",
  },
];

//
// {
//     house_air_waybill: {
//         type: 'string',
//         description:
//             'The house air waybill number, if present and able to be identified',
//     },
//     master_air_waybill: {
//         type: 'string',
//         description:
//             'The master air waybill number, if present and able to be identified',
//     },
//     shipper_details: {
//         type: 'object',
//         properties: {
//             name: {
//                 type: 'string',
//                 description: 'The name of the shipper',
//             },
//             address: {
//                 type: 'object',
//                 description: 'The address of the shipper',
//                 properties: {
//                     line1: {
//                         type: 'string',
//                         description:
//                             'The first line of the address, e.g. street address or PO box',
//                     },
//                     line2: {
//                         type: 'string',
//                         description:
//                             'The second line of the address, e.g. apartment, suite, unit, building, floor, etc.',
//                     },
//                     city: {
//                         type: 'string',
//                         description: 'The city',
//                     },
//                     state: {
//                         type: 'string',
//                         description: 'The state',
//                     },
//                     zip: {
//                         type: 'string',
//                         description: 'The zip code',
//                     },
//                     country: {
//                         type: 'string',
//                         description: 'The country',
//                     },
//                 },
//                 required: [],
//             },
//             phone: {
//                 type: 'string',
//                 description: 'The phone number of the shipper',
//             },
//             contact: {
//                 type: 'object',
//                 description: 'Details of the contact for the shipper, if present',
//                 properties: {
//                     first_name: {
//                         type: 'string',
//                         description: 'The first name of the contact',
//                     },
//                     last_name: {
//                         type: 'string',
//                         description: 'The last name of the contact',
//                     },
//                     phone: {
//                         type: 'string',
//                         description: 'The phone number of the contact',
//                     },
//                 },
//             },
//         },
//         required: [],
//     },
//     consignee_details: {
//         type: 'object',
//         properties: {
//             name: {
//                 type: 'string',
//                 description: 'The name of the consignee',
//             },
//             address: {
//                 type: 'object',
//                 description: 'The address of the consignee',
//                 properties: {
//                     line1: {
//                         type: 'string',
//                         description:
//                             'The first line of the address, e.g. street address or PO box',
//                     },
//                     line2: {
//                         type: 'string',
//                         description:
//                             'The second line of the address, e.g. apartment, suite, unit, building, floor, etc.',
//                     },
//                     city: {
//                         type: 'string',
//                         description: 'The city',
//                     },
//                     state: {
//                         type: 'string',
//                         description: 'The state',
//                     },
//                     zip: {
//                         type: 'string',
//                         description: 'The zip code',
//                     },
//                     country: {
//                         type: 'string',
//                         description: 'The country',
//                     },
//                 },
//                 required: [],
//             },
//             phone: {
//                 type: 'string',
//                 description: 'The phone number of the consignee',
//             },
//             contact: {
//                 type: 'object',
//                 description: 'Details of the contact for the consignee, if present',
//                 properties: {
//                     first_name: {
//                         type: 'string',
//                         description: 'The first name of the contact',
//                     },
//                     last_name: {
//                         type: 'string',
//                         description: 'The last name of the contact',
//                     },
//                     phone: {
//                         type: 'string',
//                         description: 'The phone number of the contact',
//                     },
//                 },
//             },
//         },
//         required: [],
//     },
//     packages: {
//         type: 'array',
//         description: 'The packages in the shipment',
//         items: {
//             type: 'object',
//             properties: {
//                 quantity: {
//                     type: 'integer',
//                     description: 'The quantity of the package',
//                 },
//                 length: {
//                     type: 'integer',
//                     description: 'The length of the package',
//                 },
//                 width: {
//                     type: 'integer',
//                     description: 'The width of the package',
//                 },
//                 height: {
//                     type: 'integer',
//                     description: 'The height of the package',
//                 },
//                 weight: {
//                     type: 'integer',
//                     description: 'The weight of the package',
//                 },
//                 description: {
//                     type: 'string',
//                     description: 'The description of the package',
//                 },
//             },
//             required: [],
//         },
//     },
//     total_weight: {
//         type: 'integer',
//         description:
//             'The total weight of the shipment, if explicitly included in the document. If not specified explicitly, do not attempt to calculate it from the package weights.',
//     },
//     dates: {
//         type: 'array',
//         description:
//             'All dates which are present in the document and not already extracted into a more specific field',
//         items: {
//             type: 'object',
//             properties: {
//                 label: {
//                     type: 'string',
//                     description:
//                         'Any label associated with the date, if present in the document',
//                 },
//                 datetime: {
//                     type: 'string',
//                     description:
//                         'The date and time, in ISO 8601 format. Pleae play close attention to the year to ensure it is extracted correctly.',
//                     format: 'date-time',
//                 },
//             },
//             required: ['datetime'],
//         },
//     },
//     instructions: {
//         type: 'array',
//         description:
//             'All natural language instructions which are present in the document and not already extracted into a more specific field',
//         items: {
//             type: 'object',
//             properties: {
//                 label: {
//                     type: 'string',
//                 },
//                 text: {
//                     type: 'string',
//                 },
//             },
//         },
//     },
//     remaining_strings: {
//         type: 'array',
//         description:
//             'All strings which are present in the document and not already extracted into a more specific field',
//         items: {
//             type: 'object',
//             properties: {
//                 label: {
//                     type: 'string',
//                 },
//                 text: {
//                     type: 'string',
//                 },
//             },
//             required: ['text'],
//         },
//     },
// };
