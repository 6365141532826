import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  Checkbox,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import {
  SchemaDocumentsBySchemaCompanyMappingDocument,
  SchemaDocumentsBySchemaCompanyMappingQuery,
  SearchSchemasDocument,
  useDeleteSchemaDocumentMutation,
} from "../../../../generated/graphql";
import DocViewerModal from "../doc-viewer-modal";
import useSchemaStore from "../schema-store";
import { Schema } from "../types";

const SchemaDocumentCard = ({
  schema,
  schemaDocument,
}: {
  schema: Schema;
  schemaDocument: SchemaDocumentsBySchemaCompanyMappingQuery["schemaDocumentsBySchemaCompanyMapping"][0];
}) => {
  const [isChecked, selectSchemaDocuments, deselectSchemaDocument] =
    useSchemaStore(
      (state) => [
        state.selectedSchemaDocuments.some(
          (s) => s.schemaDocument.uuid === schemaDocument.uuid,
        ),
        state.selectSchemaDocuments,
        state.deselectSchemaDocument,
      ],
      shallow,
    );
  const [showDocViewerModal, setShowDocViewerModal] = useState<boolean>(false);
  const [deleteSchemaDocument] = useDeleteSchemaDocumentMutation({
    refetchQueries: [
      SchemaDocumentsBySchemaCompanyMappingDocument,
      SearchSchemasDocument,
    ],
  });

  const deleteDocument = async (uuid: string) => {
    await deleteSchemaDocument({
      variables: {
        uuid,
      },
    });
    deselectSchemaDocument(schemaDocument.uuid);
  };

  const fileName = schemaDocument.key.split("/").slice(-1)[0];

  return (
    <Card key={schemaDocument.uuid} sx={{ p: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Checkbox
            checked={isChecked}
            onClick={() => {
              if (isChecked) {
                deselectSchemaDocument(schemaDocument.uuid);
              } else {
                selectSchemaDocuments(schema, [schemaDocument]);
              }
            }}
          />
          <Typography sx={{ cursor: "pointer" }} variant="caption">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={() => {
                setShowDocViewerModal(true);
              }}
            >
              {fileName}
            </Link>
          </Typography>
        </Stack>
        <IconButton
          size="small"
          onClick={() => deleteDocument(schemaDocument.uuid)}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
      <object data={schemaDocument.preSignedGetUrl} width="100%">
        {" "}
      </object>
      <DocViewerModal
        open={showDocViewerModal}
        onClose={() => {
          setShowDocViewerModal(false);
        }}
        preSignedGetUrl={schemaDocument.preSignedGetUrl}
        labelText={schema.name}
      />
    </Card>
  );
};

export default React.memo(SchemaDocumentCard);
