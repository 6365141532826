import {
  Stack,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs";
import { debounce, isNil } from "lodash";
import { DataView } from "primereact/dataview";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import React, { useState } from "react";
import {
  ScannedOrderResultsForLogsQuery,
  ScannedOrderSource,
  useScannedOrderResultsForLogsQuery,
  useSchemasQuery,
} from "../../../generated/graphql";
import ContactSelect from "../../common/contact-select";
import DateRangePicker, { DateOption } from "../../date-range-picker";
import CompanySelect, { GLOBAL_SCHEMA_NO_COMPANY } from "../company-select";
import DocViewerModal from "../schema-testing/doc-viewer-modal";
import LogItem from "./log-item";

const pageSize = 18;

const Logs = () => {
  const [companyUuid, setCompanyUuid] = useState<string>();
  const [contactUuid, setContactUuid] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>();
  const [scannedOrderSource, setScannedOrderSource] =
    useState<ScannedOrderSource | null>(null);
  const [showFailed, setShowFailed] = useState(false);
  const [dateOption, setDateOption] = useState<DateOption>({
    startDate: dayjs(new Date()).subtract(1, "days").toDate(),
    endDate: null,
  });
  const [viewingDocUrl, setViewingDocUrl] = useState<string>();
  const { data: schemasData } = useSchemasQuery({
    fetchPolicy: "cache-first",
  });
  const [page, setPage] = useState<number>(0);
  const {
    data: scannedOrderResultsData,
    loading,
    refetch,
  } = useScannedOrderResultsForLogsQuery({
    variables: {
      args: {
        first: pageSize,
        companyUuid,
        contactUuid,
        searchText,
        scannedOrderSource,
        startDate: dateOption.startDate,
        endDate: dateOption.endDate,
        failed: showFailed ? true : null,
      },
    },
  });

  const onChangeSearchText = debounce((value: string) => {
    setSearchText(value);
  }, 300);

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    let first;
    let last;
    let after;
    let before;
    if (event.page === 0) {
      first = pageSize;
    } else if (event.page === event.pageCount) {
      last = pageSize;
    } else if (event.page > page) {
      first = pageSize;
      after =
        scannedOrderResultsData?.scannedOrderResultsForAdmin.pageInfo.endCursor;
    } else {
      last = pageSize;
      before =
        scannedOrderResultsData?.scannedOrderResultsForAdmin.pageInfo
          .startCursor;
    }
    refetch({
      args: {
        first,
        last,
        after,
        before,
        companyUuid,
        searchText,
        scannedOrderSource,
        startDate: dateOption.startDate,
        endDate: dateOption.endDate,
        failed: showFailed ? true : null,
      },
    });
    setPage(event.page);
  };

  const header = () => {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <TextField
            sx={{ minWidth: 350 }}
            size="small"
            label="Search order or email"
            onChange={(e) => {
              onChangeSearchText(e.target.value);
            }}
          />
          <CompanySelect
            allowNoCompany
            currentCompanyUuid={companyUuid}
            onChange={(option) => {
              if (!isNil(option)) {
                setCompanyUuid(
                  option.value === GLOBAL_SCHEMA_NO_COMPANY
                    ? undefined
                    : option.value,
                );
              }
            }}
            noLabel
            minWidth={200}
            maxWidth={300}
          />
          <ContactSelect
            companyUuid={companyUuid}
            currentContactUuid={contactUuid}
            setContactUuid={setContactUuid}
            noLabel
            minWidth={200}
          />
          <DateRangePicker dateOption={dateOption} onChange={setDateOption} />
          <FormControl sx={{ minWidth: "100px" }}>
            <InputLabel shrink id="source-label">
              Source
            </InputLabel>
            <Select
              notched
              size="small"
              labelId="source-label"
              value={scannedOrderSource}
              label="Source"
              onChange={(event) => {
                setScannedOrderSource(
                  event.target.value === "All"
                    ? null
                    : (event.target.value as ScannedOrderSource),
                );
              }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value={ScannedOrderSource.EmailIntegration}>
                Email
              </MenuItem>
              <MenuItem value={ScannedOrderSource.UploadedDocument}>
                Uploaded
              </MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Checkbox value={showFailed} />}
            label="Failed"
            onChange={(e, checked) => {
              setShowFailed(checked);
            }}
          />
        </Stack>
        <Paginator
          first={page}
          rows={1}
          totalRecords={
            (scannedOrderResultsData?.scannedOrderResultsForAdmin.totalCount ??
              0) / pageSize
          }
          onPageChange={onPageChange}
          template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        />
      </Stack>
    );
  };

  const listTemplate = (
    scannedOrderResults: ScannedOrderResultsForLogsQuery["scannedOrderResultsForAdmin"],
  ) => {
    if (isNil(scannedOrderResults)) return undefined;
    return (
      <Grid container spacing={1}>
        {scannedOrderResults.edges.map(({ node: scannedOrderResult }) =>
          LogItem({
            scannedOrderResult,
            matchedSchemaName: schemasData?.schemas.find(
              (s) => s.uuid === scannedOrderResult.schemaUuid,
            )?.name,
            setViewingDocUrl,
          }),
        )}
      </Grid>
    );
  };

  return (
    <Stack sx={{ p: 2 }}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <DataView
        loading={loading}
        value={scannedOrderResultsData?.scannedOrderResultsForAdmin}
        listTemplate={listTemplate}
        layout="grid"
        header={header()}
      />
      {!isNil(viewingDocUrl) && (
        <DocViewerModal
          open={!isNil(viewingDocUrl)}
          onClose={() => {
            setViewingDocUrl(undefined);
          }}
          preSignedGetUrl={viewingDocUrl}
        />
      )}
    </Stack>
  );
};

export default Logs;
