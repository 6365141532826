import { RefObject, useEffect, useMemo, useState } from "react";

export const useOnScreen = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry?.isIntersecting === true),
      ),
    // eslint-disable-next-line ignore-use-effect/exhaustive-deps
    [ref],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
};
