import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Grid,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTestOrdersQuery } from "../../generated/graphql";

const TestOrderView = () => {
  const navigate = useNavigate();
  const { data: testOrdersData } = useTestOrdersQuery();
  return (
    <Grid container spacing={2} style={{ padding: 4 }}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => navigate("/test-orders/create")}
        >
          Create
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>Order</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Expected Total Charge</TableCell>
              <TableCell>Expected Total Freight Charge</TableCell>
              <TableCell>Expected Total Fuel Charge</TableCell>
              <TableCell />
            </TableHead>
            {testOrdersData?.testOrders?.testOrders?.map((testOrder) => {
              return (
                <TableRow key={testOrder.uuid}>
                  <TableCell>{`${
                    testOrder.order.standardOrderFields
                      ?.shipperBillOfLadingNumber ?? "-"
                  } (${testOrder.uuid})`}</TableCell>
                  <TableCell>{testOrder.order.company.name}</TableCell>
                  <TableCell>{testOrder.totalCharge}</TableCell>
                  <TableCell>{testOrder.totalFreightCharge}</TableCell>
                  <TableCell>{testOrder.totalFuelCharge}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`/test-orders/${testOrder.uuid}`)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TestOrderView;
