import { IconButton, Stack } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { isNil, sortBy } from "lodash";
import { useEffect, useMemo } from "react";
import Select from "react-select";
import { useCompanyContactsLazyQuery } from "../../generated/graphql";

const ContactSelect = ({
  companyUuid,
  currentContactUuid,
  setContactUuid,
  noLabel,
  minWidth,
}: {
  companyUuid: string | null | undefined;
  currentContactUuid: string | null | undefined;
  setContactUuid: (uuid: string | null) => void;
  noLabel?: boolean;
  minWidth?: number;
}) => {
  const [getCompanyContacts, { data: contactsData }] =
    useCompanyContactsLazyQuery();

  useEffect(() => {
    if (!isNil(companyUuid)) {
      getCompanyContacts({
        variables: {
          companyUuid,
        },
      });
    }
  }, [companyUuid, getCompanyContacts]);

  const contactOptions = useMemo(() => {
    if (isNil(companyUuid)) {
      return [];
    }
    return sortBy(
      contactsData?.companyContacts,
      (contact) => contact.displayName,
    ).map((contact) => ({
      value: contact.uuid,
      label: `${contact.displayName}${
        contact.isActive === false ? " (archived)" : ""
      }`,
    }));
  }, [companyUuid, contactsData?.companyContacts]);

  const currentOption = contactOptions?.find(
    (option) => option.value === currentContactUuid,
  );

  return (
    <div style={{ fontWeight: "normal" }}>
      {noLabel !== true && <div>Select contact</div>}
      <Stack direction="row" spacing={0.5} sx={{ minWidth }}>
        <Select
          menuPosition="fixed"
          options={contactOptions}
          onChange={(option) => {
            if (!isNil(option) && !isNil(option.value)) {
              setContactUuid(option.value);
            }
          }}
          placeholder="Select contact"
          value={currentOption ?? null}
        />
        {!isNil(currentContactUuid) && (
          <IconButton
            size="small"
            onClick={() => {
              setContactUuid(null);
            }}
          >
            <ClearIcon />
          </IconButton>
        )}
      </Stack>
    </div>
  );
};

export default ContactSelect;
