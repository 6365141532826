import Home from "../components/home";
import { RequireAuth } from "../hooks/use-auth";

const HomePage = () => {
  return (
    <RequireAuth>
      <Home />
    </RequireAuth>
  );
};

export default HomePage;
