import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  SchemaCompanyMappingsDocument,
  SearchSchemasDocument,
  useCreateSchemaCompanyMappingMutation,
  useSchemaCompanyMappingsLazyQuery,
} from "../../../../generated/graphql";
import ContactSelect from "../../../common/contact-select";
import CompanySelect from "../../company-select";
import SchemaSelect from "../../schema-select";
import TerminalSelect from "../../terminal-select";

const AddSchemaCompanyMappingModal = ({
  open,
  setOpen,
  schemaUuid,
  initialCompanyUuid,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  schemaUuid?: string;
  initialCompanyUuid?: string;
}) => {
  const [selectedSchemaUuid, setSelectedSchemaUuid] = useState<
    string | undefined
  >(schemaUuid);
  const [companyUuid, setCompanyUuid] = useState<string | undefined>(
    initialCompanyUuid,
  );
  const [contactUuid, setContactUuid] = useState<string | null>(null);
  const [terminalUuid, setTerminalUuid] = useState<string | null>(null);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [createSchemaCompanyMapping] = useCreateSchemaCompanyMappingMutation({
    refetchQueries: [SchemaCompanyMappingsDocument, SearchSchemasDocument],
  });
  const [getSchemaCompanyMappings, { data: schemaCompanyMappingsData }] =
    useSchemaCompanyMappingsLazyQuery();

  const mappingAlreadyExists = useMemo(() => {
    return (
      schemaCompanyMappingsData?.schemaCompanyMappings.some(
        (s) => s.companyUuid === companyUuid,
      ) === true
    );
  }, [schemaCompanyMappingsData?.schemaCompanyMappings, companyUuid]);

  useEffect(() => {
    if (!isNil(schemaUuid)) {
      getSchemaCompanyMappings({
        variables: {
          schemaUuid,
        },
      });
    }
  }, [schemaUuid]);

  const create = async () => {
    setErrorText(undefined);
    if (isNil(companyUuid)) {
      setErrorText("Missing company");
      return;
    }
    if (isNil(contactUuid)) {
      setErrorText("Missing contact");
      return;
    }
    if (isNil(selectedSchemaUuid)) {
      setErrorText("Missing schema");
      return;
    }

    const res = await createSchemaCompanyMapping({
      variables: {
        createSchemaCompanyMappingInput: {
          companyUuid,
          contactUuid,
          schemaUuid: selectedSchemaUuid,
          terminalUuid,
        },
      },
    });

    if (!isNil(res.errors)) {
      setErrorText(res.errors[0]?.message);
      return;
    }
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Stack justifyContent="space-between" direction="row">
          <DialogTitle sx={{ fontWeight: "bold" }}>
            Add Schema Mapping
          </DialogTitle>
        </Stack>
        <Stack spacing={2} sx={{ px: 3 }}>
          {isNil(schemaUuid) && (
            <SchemaSelect
              schemaUuid={selectedSchemaUuid}
              setSchemaUuid={(uuid) => {
                getSchemaCompanyMappings({
                  variables: {
                    schemaUuid: uuid,
                  },
                });
                setSelectedSchemaUuid(uuid);
              }}
            />
          )}
          <CompanySelect
            currentCompanyUuid={companyUuid}
            onChange={(option) => {
              if (!isNil(option)) {
                setCompanyUuid(option.value);
                setContactUuid(null);
                setTerminalUuid(null);
              }
            }}
          />
          <ContactSelect
            companyUuid={companyUuid}
            currentContactUuid={contactUuid}
            setContactUuid={setContactUuid}
          />
          <TerminalSelect
            companyUuid={companyUuid}
            currentTerminalUuid={terminalUuid}
            setTerminalUuid={setTerminalUuid}
            showLabel
          />
          <Stack spacing={2}>
            {mappingAlreadyExists && (
              <Typography color="error" variant="caption">
                Warning: schema has already been mapped to the company
              </Typography>
            )}
            {!isNil(errorText) && (
              <Typography variant="caption" color="error">
                {errorText}
              </Typography>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={create} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSchemaCompanyMappingModal;
