import CreateTestOrderForm from "../../../components/test-orders/create-test-order-form";
import { RequireAuth } from "../../../hooks/use-auth";

const TestOrderCreatePage = () => {
  return (
    <RequireAuth>
      <CreateTestOrderForm />
    </RequireAuth>
  );
};

export default TestOrderCreatePage;
