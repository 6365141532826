import { useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { MatcherType } from "../../../generated/graphql";
import { enumKeys } from "../../../utils/enum";

type AddFieldMatcherModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleCreate: ({ type, value }: { type: MatcherType; value: string }) => void;
};

const AddFieldMatcherModal = ({
  isOpen,
  onClose,
  handleCreate,
}: AddFieldMatcherModalProps) => {
  const [matcherType, setMatcherType] = useState<MatcherType>();
  const [matcherValue, setMatcherValue] = useState<string>("");

  const matcherTypeOptions = enumKeys(MatcherType).map((key) => ({
    value: MatcherType[key],
    label: MatcherType[key],
  }));

  return (
    <Modal
      contentLabel="Add field matcher"
      style={{
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "600px",
          width: "800px",
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "black",
        }}
      >
        <h3>Select matcher type</h3>
        <Select
          required
          onChange={(option) => {
            if (option) {
              setMatcherType(option.value as MatcherType);
            }
          }}
          options={matcherTypeOptions}
          styles={{
            control: (base) => ({
              ...base,
              width: "max-content",
              minWidth: "100%",
            }),
            menu: (base) => ({
              ...base,
              width: "max-content",
              minWidth: "100%",
            }),
          }}
        />
        <h3>Set value</h3>
        <input
          required
          onChange={(e) => setMatcherValue(e.target.value)}
          value={matcherValue}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <button
          style={{ marginRight: "10px", height: "30px" }}
          onClick={() => {
            if (matcherType == null) {
              alert("All values are required");
            } else {
              handleCreate({
                type: matcherType,
                value: matcherValue,
              });
            }
          }}
        >
          Add
        </button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </Modal>
  );
};

export default AddFieldMatcherModal;
