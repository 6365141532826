import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

const KeyOverrides = ({
  keys,
  setKeys,
  label,
}: {
  keys: string[];
  setKeys: Dispatch<SetStateAction<string[]>>;
  label: string;
}) => {
  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Typography>{label}</Typography>
        <Button
          onClick={() => {
            setKeys((prev) => [...prev, "New"]);
          }}
        >
          Add key
        </Button>
      </Stack>
      <Grid container spacing={1} sx={{ pr: 1 }}>
        {keys.map((key, idx) => (
          <Grid item xs={3} key={key}>
            <TextField
              InputProps={{
                style: {
                  padding: 0.5,
                  fontSize: 10,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography variant="caption" sx={{ pl: "5px" }}>
                      {idx + 1}
                    </Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setKeys((prevKeys) => {
                          const newKeys = [...prevKeys];
                          newKeys.splice(idx, 1);
                          return newKeys;
                        });
                      }}
                    >
                      <DeleteIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: "fit-content" }}
              size="small"
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              value={key}
              onChange={(e) => {
                setKeys((prevKeys: string[]) => {
                  const newKeys = [...prevKeys];
                  newKeys[idx] = e.target.value;
                  return newKeys;
                });
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default KeyOverrides;
