import { isEmpty, isNil } from "lodash";
import { Resolver, useForm } from "react-hook-form";
import { isNilOrEmptyString } from "shared/string";
import { SpecialAccessorialType } from "../../generated/graphql";
import { AccessorialImportType } from "./types";

const NO_ZONE_GROUP = "None";

export type ImportAccessorialFormValues = {
  accessorialUrl: string;
  name?: string | null;
  code?: string | null;
  accessorialImportType: AccessorialImportType;
  percentForSettlement?: string | null;
  fuelSurchargePercentageRate?: string | null;
  terminalUuid?: string | null;
  contactUuids: string[];
  tariffZoneGroupId: string | null;
  specialAccessorialType?: SpecialAccessorialType | null;
};

const resolver: Resolver<ImportAccessorialFormValues> = async (formValues) => {
  const errors: {
    [property in keyof ImportAccessorialFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  const {
    accessorialUrl,
    name,
    accessorialImportType,
    percentForSettlement,
    tariffZoneGroupId,
    specialAccessorialType,
  } = formValues;

  if (isNil(accessorialUrl) || isEmpty(accessorialUrl)) {
    errors.accessorialUrl = {
      type: "required",
      message: "Accessorial URL required",
    };
  }

  if (isNil(accessorialImportType) || isEmpty(accessorialImportType)) {
    errors.accessorialImportType = {
      type: "required",
      message: "Accessorial Import Type required",
    };
  }

  if (accessorialImportType === AccessorialImportType.SPECIAL) {
    if (isNil(name) || isEmpty(name)) {
      errors.name = {
        type: "required",
        message: "Name required",
      };
    }

    if (isNil(percentForSettlement) || isEmpty(percentForSettlement)) {
      errors.percentForSettlement = {
        type: "required",
        message: "Settlement Percentage rate required",
      };
    }

    if (specialAccessorialType !== SpecialAccessorialType.MileageBased) {
      if (
        isNilOrEmptyString(tariffZoneGroupId) ||
        tariffZoneGroupId === NO_ZONE_GROUP
      ) {
        errors.tariffZoneGroupId = {
          type: "required",
          message: "Zone group is required",
        };
      }
    }

    if (isNil(specialAccessorialType)) {
      errors.specialAccessorialType = {
        type: "required",
        message: "Special accessorial type is required",
      };
    }
  }

  return {
    values: formValues,
    errors,
  };
};

const useImportAccessorialForm = () => {
  return useForm({ resolver });
};

export default useImportAccessorialForm;
