import { Fade, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useRef, useState } from "react";
import "react-json-view-lite/dist/index.css";
import { shallow } from "zustand/shallow";
import useSchemaStore from "../schema-store";
import { MatchStatus } from "../types";
import DocScanningMatchJobTable from "./doc-scanning-match-job-table";
import DocScanningScanJobTable from "./doc-scanning-scan-job-table";

const DocScanningJobProgress = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMinimized, setIsMinimized] = useState(true);
  const [matchSchemaDocumentJobs, isViewingScanResult] = useSchemaStore(
    (state) => [state.matchSchemaDocumentJobs, state.isViewingScanResult],
    shallow,
  );

  const countFailedMatchJobs = matchSchemaDocumentJobs.filter(
    (job) => job.matchStatus === MatchStatus.FAILED,
  ).length;

  const shouldMinimize = isMinimized && !isViewingScanResult;

  return (
    <Fade in>
      <Stack
        onMouseEnter={() => {
          setIsMinimized(false);
        }}
        onMouseLeave={() => {
          setIsMinimized(true);
        }}
        ref={ref}
        sx={{
          position: "absolute",
          left: shouldMinimize ? "calc(100vw - 40x)" : undefined,
          right: 0,
          bottom: 0,
          backgroundColor: "white",
          width: shouldMinimize ? 270 : 1100,
          border: 1,
          borderColor: "#e0e0e0",
          p: 1,
          zIndex: 1000,
          borderRadius: "4px",
        }}
      >
        <DocScanningScanJobTable isMinimized={shouldMinimize} />
        {!isEmpty(matchSchemaDocumentJobs) && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="column" justifyContent="left">
                <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                  Matching Documents (
                  {
                    matchSchemaDocumentJobs.filter(
                      (job) => job.matchStatus !== MatchStatus.IN_PROGRESS,
                    ).length
                  }{" "}
                  / {matchSchemaDocumentJobs.length})
                </Typography>
                {countFailedMatchJobs > 0 && (
                  <Typography variant="caption" color="error">
                    {countFailedMatchJobs} failed
                  </Typography>
                )}
              </Stack>
            </Stack>
            {!shouldMinimize && (
              <Stack spacing={2}>
                <DocScanningMatchJobTable
                  matchSchemaDocumentJobs={matchSchemaDocumentJobs}
                />
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Fade>
  );
};

export default React.memo(DocScanningJobProgress);
