import { groupBy, isNil } from 'lodash';
import {
  AccessLevel,
  type MeRoleFragment,
  type MeRolePermissionFragment,
  PermissionResource,
  UserFragment,
} from './generated/graphql';
import { exhaustive } from './switch';
import { isNilOrEmptyString } from './string';

export type UserPermissionWithAccessLevel = {
  permissionResource: PermissionResource;
  accessLevel: AccessLevel | null;
};

export const getMaximumAccessLevel = (
  permissions: MeRolePermissionFragment[],
): AccessLevel | null => {
  const permissionAccessLevels = permissions.map((p) => p.accessLevel);
  if (permissionAccessLevels.includes(AccessLevel.Write)) {
    return AccessLevel.Write;
  }
  if (permissionAccessLevels.includes(AccessLevel.Read)) {
    return AccessLevel.Read;
  }
  return null;
};

export const buildUserPermissionsList = (
  roles: MeRoleFragment[],
): UserPermissionWithAccessLevel[] => {
  const permissions = roles.flatMap((role) => role.permissions);
  const permissionsByResource = groupBy(permissions, 'permissionResource');
  const finalUserPermissionsWithAccessLevel: UserPermissionWithAccessLevel[] =
    Object.keys(permissionsByResource).map((permissionResource) => ({
      permissionResource: permissionResource as PermissionResource,
      accessLevel: getMaximumAccessLevel(
        permissionsByResource?.[permissionResource] ?? [],
      ),
    }));
  return finalUserPermissionsWithAccessLevel;
};

export const getPermissionAccessLevel = (
  userPermissionWithAccessLevels: UserPermissionWithAccessLevel[],
  permissionResource: PermissionResource,
): AccessLevel | null => {
  return (
    userPermissionWithAccessLevels.find(
      (userPermissionWithAccessLevel) =>
        userPermissionWithAccessLevel.permissionResource === permissionResource,
    )?.accessLevel ?? null
  );
};

export type PermissionFlags = {
  canRead: boolean;
  canWrite: boolean;
  hasMasterPermission: boolean;
};

export const getPermissionsFlags = (
  userPermissionWithAccessLevels: UserPermissionWithAccessLevel[],
  permissionResource: PermissionResource,
): PermissionFlags => {
  const accessLevel = getPermissionAccessLevel(
    userPermissionWithAccessLevels,
    permissionResource,
  );
  const masterPermissionResource = userPermissionWithAccessLevels.find(
    (userPermissionWithAccessLevel) =>
      userPermissionWithAccessLevel.permissionResource ===
      PermissionResource.Master,
  );
  if (!isNil(masterPermissionResource)) {
    return { canRead: true, canWrite: true, hasMasterPermission: true };
  }
  if (isNil(accessLevel)) {
    return { canRead: false, canWrite: false, hasMasterPermission: false };
  }
  if (accessLevel === AccessLevel.Write) {
    return { canRead: true, canWrite: true, hasMasterPermission: false };
  }

  return { canRead: true, canWrite: false, hasMasterPermission: false };
};

/*
  Users must have either an email or phone number defined. This is enforced by the database, but is not reflected in our schema. This util will return the email if it exists, otherwise the phone number, then throw an error if neither exist.
*/
export const getUserIdentifier = (user: {
  email?: string | null;
  phoneNumber?: string | null;
  uuid: string;
}): string => {
  if (!isNilOrEmptyString(user.email)) {
    return user.email;
  }
  if (!isNilOrEmptyString(user.phoneNumber)) {
    return user.phoneNumber;
  }
  return user.uuid;
};
