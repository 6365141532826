/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPermissionsFlags } from "shared/roles";
import { PermissionResource } from "../generated/graphql";
import useUserRoles from "./use-user-roles";

export const useAuth = () => {
  const { userRolesLoading, userPermissions } = useUserRoles();
  const navigate = useNavigate();

  const { canRead: canReadInternalTools } = getPermissionsFlags(
    userPermissions,
    PermissionResource.InternalTools,
  );

  useEffect(() => {
    if (userRolesLoading !== false) {
      return;
    }
    if (canReadInternalTools !== true) {
      navigate("/login");
    }
  }, [canReadInternalTools, userRolesLoading]);

  return canReadInternalTools === true;
};

export const RequireAuth = (props: any) => useAuth() && props.children;
