import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Edi = () => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h4">EDI Tool</Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" onClick={() => navigate("/edi/view")}>
          View EDI Transactions for Partnership
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" onClick={() => navigate("/edi")}>
          Send EDI Transaction for Partnership
        </Button>
      </Grid>
    </Grid>
  );
};

export default Edi;
