import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack } from "@mui/material";
import { isNil, sortBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import Select from "react-select";
import { useTerminalsForInternalLazyQuery } from "../../generated/graphql";

const TerminalSelect = ({
  companyUuid,
  currentTerminalUuid,
  setTerminalUuid,
  showLabel = false,
}: {
  companyUuid: string | null | undefined;
  currentTerminalUuid: string | null | undefined;
  setTerminalUuid: (uuid: string | null) => void;
  showLabel?: boolean;
}) => {
  const [getCompanyTerminals, { data: terminalsData }] =
    useTerminalsForInternalLazyQuery({
      fetchPolicy: "cache-first",
    });

  useEffect(() => {
    if (!isNil(companyUuid)) {
      getCompanyTerminals({
        variables: {
          getTerminalsInternalInput: {
            companyUuid,
            excludeNotActive: false,
          },
        },
      });
    }
  }, [companyUuid]);

  const terminalOptions = useMemo(
    () =>
      sortBy(terminalsData?.terminalsForInternal, (t) => t.code).map(
        (terminal) => ({
          value: terminal.uuid,
          label: `${terminal.code}${terminal.isActive === false ? " (archived)" : ""}`,
        }),
      ),
    [terminalsData],
  );

  const currentOption = terminalOptions?.find(
    (option) => option.value === currentTerminalUuid,
  );

  return (
    <div>
      {showLabel && <div>Select terminal</div>}
      <Stack direction="row" spacing={0.5}>
        <Select
          menuPosition="fixed"
          options={terminalOptions}
          onChange={(option) => {
            if (!isNil(option) && !isNil(option.value)) {
              setTerminalUuid(option.value);
            }
          }}
          placeholder="Select terminal"
          value={currentOption ?? null}
        />
        <Box>
          {!isNil(currentOption) && (
            <IconButton
              size="small"
              onClick={() => {
                setTerminalUuid(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Stack>
    </div>
  );
};

export default TerminalSelect;
