import CompanyConfigurations from "../../components/company-configurations/company-configurations";
import { RequireAuth } from "../../hooks/use-auth";

const CompanyConfigurationsPage = () => {
  return (
    <RequireAuth>
      <CompanyConfigurations />
    </RequireAuth>
  );
};

export default CompanyConfigurationsPage;
