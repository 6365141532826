import { isNil } from "lodash";
import { useEffect } from "react";

const StorybookPage = () => {
  useEffect(() => {
    if (!isNil(process.env.REACT_APP_STORYBOOK_REDIRECT_URL)) {
      window.location.href = process.env.REACT_APP_STORYBOOK_REDIRECT_URL;
    }
  }, []);

  return null;
};

export default StorybookPage;
