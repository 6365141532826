import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { debounce, isNil, truncate } from "lodash";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useMemo, useState } from "react";
import { RenderServiceWithDeploysEntity } from "../../generated/graphql";

const CommitHashLink = ({ commitHash }: { commitHash: string }) => (
  <a
    href={`https://github.com/cashew-systems/tms-monorepo/commit/${commitHash}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {commitHash}
  </a>
);

const CommitMessageWithTooltip = ({
  commitMessage,
}: {
  commitMessage: string;
}) => (
  <Tooltip title={commitMessage} placement="top">
    <span>{truncate(commitMessage, { length: 100 })}</span>
  </Tooltip>
);

const RenderServiceTable = ({
  service,
}: {
  service: RenderServiceWithDeploysEntity;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const onChangeSearchText = debounce((value: string) => {
    setSearchText(value);
  }, 200);

  const filteredDeploys = useMemo(() => {
    return service.deploys
      .filter((deploy) =>
        deploy.commitHash.toLowerCase().startsWith(searchText.toLowerCase()),
      )
      .sort((a, b) => {
        if (isNil(a.finishedAt) && isNil(b.finishedAt)) return 0;
        if (isNil(a.finishedAt)) return -1;
        if (isNil(b.finishedAt)) return 1;
        return (
          new Date(b.finishedAt).getTime() - new Date(a.finishedAt).getTime()
        );
      });
  }, [service.deploys, searchText]);

  const header = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: 1,
        borderBottom: 0,
        borderColor: "#E9ECEF",
        backgroundColor: "#f8f9fa",
        p: 0.5,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>{service.name}</Typography>
        <Divider orientation="vertical" flexItem />
        <Typography>Branch: {service.branch}</Typography>
        <Divider orientation="vertical" flexItem />
        <TextField
          size="small"
          label="Search commit hash"
          onChange={(e) => {
            onChangeSearchText(e.target.value);
          }}
        />
      </Stack>
      <IconButton
        size="small"
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
      >
        {isExpanded ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
      </IconButton>
    </Stack>
  );

  return (
    <Stack>
      <div
        key={service.id}
        style={
          isExpanded
            ? {
                position: "absolute",
                zIndex: 10000,
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "white",
              }
            : {}
        }
      >
        {header}
        <DataTable
          key={`${service.id}-open-${isExpanded}`}
          value={filteredDeploys}
          showGridlines
          size="small"
          scrollable
          removableSort
          scrollHeight={isExpanded ? "100vh" : "325px"}
          stripedRows
          virtualScrollerOptions={{ itemSize: 50 }}
        >
          <Column
            field="commitHash"
            header="Commit Hash"
            style={{ width: "40%" }}
            // eslint-disable-next-line react/no-unstable-nested-components
            body={(rowData) => (
              <CommitHashLink commitHash={rowData.commitHash} />
            )}
          />
          <Column
            field="commitMessage"
            header="Commit Message"
            // eslint-disable-next-line react/no-unstable-nested-components
            body={(rowData) => (
              <CommitMessageWithTooltip commitMessage={rowData.commitMessage} />
            )}
            style={{ width: "40%" }}
          />
          <Column
            field="finishedAt"
            header="Finished At"
            body={(rowData) =>
              isNil(rowData.finishedAt)
                ? "In Progress"
                : new Date(rowData.finishedAt).toLocaleString()
            }
            style={{ width: "20%" }}
          />
        </DataTable>
      </div>
    </Stack>
  );
};

export default RenderServiceTable;
