import { SxProps } from "@mui/material";

const useStyles = () => {
  return {
    filterButton: {
      borderRadius: "4px",
    },
    menuText: {
      fontSize: "14px",
    },
    filterTitle: {
      fontSize: "14px",
      ml: "3px",
    },
    filterValue: {
      fontSize: "14px",
      ml: "5px",
      fontWeight: "bold",
    },
    center: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    } as SxProps,
    cardHover: {
      "&:hover": {
        background: "rgba(37, 48, 82, 0.08)",
        transition: "0.15s",
        cursor: "pointer",
      },
    },
    modal: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      height: "85vh",
      width: "60vw",
      boxShadow: 24,
      p: 3,
    },
  };
};

export default useStyles;
