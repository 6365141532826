import ContactsImporter from "../../components/contacts-importer/contacts-importer";
import { RequireAuth } from "../../hooks/use-auth";

const ContactsImporterPage = () => {
  return (
    <RequireAuth>
      <ContactsImporter />
    </RequireAuth>
  );
};

export default ContactsImporterPage;
