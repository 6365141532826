/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { get, isNil, set, unset } from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { StructuredExtractionOverride } from "../types";

const OverridesTable = ({
  schemaOverride,
  setSchemaOverride,
  extractionSchemaOverrides,
  basePath,
}: {
  schemaOverride: any;
  setSchemaOverride: Dispatch<SetStateAction<any>>;
  extractionSchemaOverrides: StructuredExtractionOverride[];
  basePath: string[];
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell width={100}>Field</TableCell>
          <TableCell>Instructions</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {extractionSchemaOverrides.map((override) => {
          const path = [...basePath, ...override.path, ...["description"]];
          const value = get(schemaOverride, path);

          if (isNil(value)) {
            return null;
          }
          return (
            <TableRow key={override.label}>
              <TableCell>
                <Typography variant="caption">{override.label}</Typography>
              </TableCell>
              <TableCell>
                <Box>
                  <TextField
                    multiline
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: {
                        padding: 3,
                        paddingLeft: 5,
                        fontSize: 12,
                      },
                    }}
                    value={value}
                    onChange={(e) => {
                      let newSchemaOverride = {
                        ...schemaOverride,
                      };
                      newSchemaOverride = set(
                        newSchemaOverride,
                        path,
                        e.target.value,
                      );
                      setSchemaOverride(newSchemaOverride);
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    const newSchemaOverride = {
                      ...schemaOverride,
                    };
                    unset(newSchemaOverride, path);
                    setSchemaOverride(newSchemaOverride);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default OverridesTable;
