import { isNil } from "lodash";
import { FieldMapping } from "../generated/graphql";

export const isPackageField = ({
  fieldMapping,
}: {
  fieldMapping: FieldMapping;
}): boolean => {
  return fieldMapping.toString().toLowerCase().startsWith("package");
};

/**
 * Increment a package field to the next higher package field.
 *
 * e.g. PACAKGE_0_LENGTH -> PACKAGE_1_LENGTH
 */
export const incrementPackageField = ({
  fieldMapping,
}: {
  fieldMapping: FieldMapping;
}): FieldMapping => {
  const ordinalStr = fieldMapping.toString().split("_")[1];
  const field = fieldMapping.toString().split("_")[2];
  if (isNil(ordinalStr)) {
    return fieldMapping;
  }
  const ordinal = parseInt(ordinalStr, 10) + 1;
  return `PACKAGE_${ordinal}_${field}` as FieldMapping;
};
