import { Button, Box, Stack, Grid, Typography, Dialog } from "@mui/material";
import { isNil } from "lodash";
import React from "react";

const DocViewerModal = ({
  open,
  onClose,
  preSignedGetUrl,
  labelText,
}: {
  open: boolean;
  onClose: () => void;
  preSignedGetUrl: string | undefined;
  labelText?: string;
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" sx={{ zIndex: 2500 }}>
      <Box sx={{ width: "50vw", p: 2 }}>
        {!isNil(preSignedGetUrl) ? (
          <Stack spacing={2} direction="column">
            <Grid container alignItems="center">
              <Grid item xs={6}>
                {!isNil(labelText) && (
                  <Typography sx={{ fontSize: "16px", color: "gray" }}>
                    {labelText}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{ float: "right" }}
                  onClick={() => {
                    window.open(preSignedGetUrl, "_blank");
                  }}
                >
                  View Original
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ height: "80vh", overflowY: "scroll" }}>
              <object
                data={preSignedGetUrl}
                width="100%"
                style={{ height: "99%" }}
              >
                {" "}
              </object>
            </Box>
          </Stack>
        ) : (
          "Loading..."
        )}
      </Box>
    </Dialog>
  );
};

export default React.memo(DocViewerModal);
