import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Card,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, lightBlue, orange, red } from "@mui/material/colors";
import { sentenceCase } from "change-case";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { isNil } from "lodash";
import React from "react";
import { exhaustive } from "shared/switch";
import {
  ScannedOrderResultsForLogsQuery,
  ScannedOrderResultStatus,
  ScannedOrderSource,
} from "../../../generated/graphql";

dayjs.extend(utc);
dayjs.extend(timezone);

type ScannedOrderResult =
  ScannedOrderResultsForLogsQuery["scannedOrderResultsForAdmin"]["edges"][0]["node"];

const scannedOrderResultStatusChip = ({
  status,
}: {
  status: ScannedOrderResultStatus;
}) => {
  let color;
  let backgroundColor;
  switch (status) {
    case ScannedOrderResultStatus.InProgress:
      color = lightBlue["900"];
      backgroundColor = lightBlue["50"];
      break;
    case ScannedOrderResultStatus.Approved:
    case ScannedOrderResultStatus.Attached:
    case ScannedOrderResultStatus.InReview:
    case ScannedOrderResultStatus.Duplicate:
      color = green["900"];
      backgroundColor = "#BBF7D0";
      break;
    case ScannedOrderResultStatus.Failed:
    case ScannedOrderResultStatus.ManuallyEntered:
      color = red["900"];
      backgroundColor = red["50"];
      break;
    case ScannedOrderResultStatus.Rejected:
      color = orange["900"];
      backgroundColor = orange["50"];
      break;
    default:
      return exhaustive(status);
  }

  return (
    <Chip
      size="small"
      variant="outlined"
      sx={{
        height: 18,
        fontSize: "12px",
        fontWeight: "500",
        borderColor: color,
        color,
        backgroundColor,
      }}
      label={sentenceCase(status).toUpperCase()}
    />
  );
};

const scannedOrderSourceIcon = ({ source }: { source: ScannedOrderSource }) => {
  switch (source) {
    case ScannedOrderSource.EmailIntegration:
      return <AttachEmailIcon sx={{ color: "gray", fontSize: 14 }} />;
    case ScannedOrderSource.UploadedDocument:
      return <UploadFileIcon sx={{ color: "gray", fontSize: 14 }} />;
    default:
      return exhaustive(source);
  }
};

const LogItem = ({
  scannedOrderResult,
  matchedSchemaName,
  setViewingDocUrl,
}: {
  scannedOrderResult: ScannedOrderResult;
  matchedSchemaName: string | undefined;
  setViewingDocUrl: (url: string | undefined) => void;
}) => {
  const {
    company,
    createdAt,
    scannedOrder: { ingestedOutlookEmailDetails: emailDetails },
    scannedOrder,
    order,
    failureReason,
    status,
    preSignedGetUrl,
  } = scannedOrderResult;

  return (
    <Grid item xs={2}>
      <Card sx={{ p: 1 }}>
        <Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip
              arrow
              placement="right"
              disableHoverListener={isNil(emailDetails)}
              title={
                <Stack spacing={1}>
                  <span>{emailDetails?.fromAddress}</span>
                  <span>{emailDetails?.subject}</span>
                </Stack>
              }
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                {scannedOrderSourceIcon({
                  source: scannedOrder.source,
                })}
                <Typography variant="caption">
                  {company.name.slice(0, 20)}
                  {company.name.length > 20 ? "..." : ""}
                </Typography>
              </Stack>
            </Tooltip>
            {scannedOrderResultStatusChip({
              status,
            })}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setViewingDocUrl(preSignedGetUrl ?? undefined);
            }}
          >
            {!isNil(order?.billingPartyContact.displayName) && (
              <Tooltip title={`Matched to: ${matchedSchemaName ?? "?"}`}>
                <Typography variant="caption" color="secondary">
                  {order?.billingPartyContact.displayName.slice(0, 20)}
                  {(order?.billingPartyContact.displayName.length ?? 0) > 20
                    ? "..."
                    : ""}
                </Typography>
              </Tooltip>
            )}
            {!isNil(order?.standardOrderFields.shipperBillOfLadingNumber) && (
              <Typography variant="caption" color="secondary">
                {order?.standardOrderFields.shipperBillOfLadingNumber}
              </Typography>
            )}
            {!isNil(failureReason) && (
              <Typography variant="caption" color="error">
                {sentenceCase(failureReason)}
              </Typography>
            )}
          </Stack>
          <object data={preSignedGetUrl ?? undefined}> </object>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <EditCalendarIcon sx={{ color: "gray", fontSize: 14 }} />
              <Typography variant="caption">
                {dayjs(createdAt).format("M/D H:mm:ss")}
              </Typography>
            </Stack>
            {scannedOrder.source === ScannedOrderSource.EmailIntegration && (
              <Typography variant="caption">
                {dayjs(createdAt).diff(
                  scannedOrder.ingestedOutlookEmailDetails?.receivedAt,
                  "seconds",
                )}{" "}
                sec
              </Typography>
            )}
            <IconButton
              size="small"
              sx={{ p: 0.25 }}
              onClick={() => {
                navigator.clipboard.writeText(order?.uuid ?? "");
              }}
            >
              <ContentCopyIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
};

export default LogItem;
