import TestOrderView from "../../components/test-orders/test-order-view";
import { RequireAuth } from "../../hooks/use-auth";

const TestOrderPage = () => {
  return (
    <RequireAuth>
      <TestOrderView />
    </RequireAuth>
  );
};

export default TestOrderPage;
