import { isEmpty, isNil } from "lodash";
import { Resolver, useForm } from "react-hook-form";

export type DriverImporterFormValues = {
  driversUrl: string;
  companyUuid: string;
  userUuid: string;
};

const resolver: Resolver<DriverImporterFormValues> = async (formValues) => {
  const errors: {
    [property in keyof DriverImporterFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  const { driversUrl } = formValues;

  if (isNil(driversUrl) || isEmpty(driversUrl)) {
    errors.driversUrl = {
      type: "required",
      message: "Drivers URL is required",
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useImportDriversForm = () => {
  return useForm({ resolver });
};

export default useImportDriversForm;
