import AccessorialImporter from "../../components/accessorial-importer/accessorial-importer";
import { RequireAuth } from "../../hooks/use-auth";

const AccessorialImporterPage = () => {
  return (
    <RequireAuth>
      <AccessorialImporter />
    </RequireAuth>
  );
};

export default AccessorialImporterPage;
