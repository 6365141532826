import OrdersImporter from "../../components/orders-importer/orders-importer";
import { RequireAuth } from "../../hooks/use-auth";

const OrdersImporterPage = () => {
  return (
    <RequireAuth>
      <OrdersImporter />
    </RequireAuth>
  );
};

export default OrdersImporterPage;
