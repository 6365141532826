import CrownChargesValidator from "../../components/crown-charges-validator/crown-charges-validator";
import { RequireAuth } from "../../hooks/use-auth";

const CrownChargesValidatorPage = () => {
  return (
    <RequireAuth>
      <CrownChargesValidator />
    </RequireAuth>
  );
};

export default CrownChargesValidatorPage;
