import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  PackageField,
  PackageTableColumnFragment,
} from "../../../generated/graphql";
import { enumKeys } from "../../../utils/enum";

type EditPackageTableModalProps = {
  isOpen: boolean;
  onClose: () => void;
  packageTableColumns: PackageTableColumnFragment[];
  handleSave: (columns: PackageTableColumnFragment[]) => void;
};

const EditPackageTableModal = ({
  handleSave,
  isOpen,
  onClose,
  packageTableColumns,
}: EditPackageTableModalProps) => {
  const [columns, setColumns] = useState<PackageTableColumnFragment[]>([]);

  useEffect(() => {
    if (isOpen) {
      setColumns([...packageTableColumns]);
    } else {
      setColumns([]);
    }
  }, [isOpen]);

  return (
    <Modal
      contentLabel="Edit package table columns"
      style={{
        content: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "75%",
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "black",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <h3>Edit package table</h3>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Header</TableCell>
              <TableCell>Package field</TableCell>
              <TableCell>Is required?</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {columns.map((column, idx) => {
              return (
                <TableRow key={column.uuid}>
                  <TableCell>{column.header}</TableCell>
                  <TableCell>
                    <select
                      onChange={(e) => {
                        setColumns((c) => {
                          const { value } = e.target;
                          if (value === "None") {
                            return c;
                          }
                          return [
                            ...c.slice(0, idx),
                            {
                              ...column,
                              packageField: value as PackageField,
                            },
                            ...c.slice(idx + 1),
                          ];
                        });
                      }}
                      value={column.packageField ?? undefined}
                    >
                      <option key={undefined} value={undefined}>
                        None
                      </option>
                      {enumKeys(PackageField).map((f) => {
                        return (
                          <option key={PackageField[f]} value={PackageField[f]}>
                            {f}
                          </option>
                        );
                      })}
                    </select>
                  </TableCell>
                  <TableCell>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setColumns((c) => {
                          return [
                            ...c.slice(0, idx),
                            {
                              ...column,
                              required: e.target.checked,
                            },
                            ...c.slice(idx + 1),
                          ];
                        });
                      }}
                      checked={column.required}
                    />
                  </TableCell>
                  <TableCell>
                    <button>Clear</button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <button
          onClick={() => {
            handleSave(columns);
            setColumns([]);
            onClose();
          }}
        >
          Save changes
        </button>
        <button
          onClick={() => {
            setColumns([]);
            onClose();
          }}
        >
          Discard
        </button>
      </div>
    </Modal>
  );
};

export default EditPackageTableModal;
